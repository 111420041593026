import {LocaleObject} from '@nuxtjs/i18n/dist/runtime/composables'

export const defaultLocale = 'en'
export const locales: LocaleObject[] = [
  {
    code: 'en',
    name: 'English',
    iso: 'en',
    isCatchallLocale: true,
    dir: 'ltr',
  },
  {
    code: 'nl',
    name: 'Netherlands',
    iso: 'nl',
    dir: 'ltr',
  },
  {
    code: 'de',
    name: 'Deutsch',
    iso: 'de',
    dir: 'ltr',
  },
]
